.gradientBackground {
  padding-top: 60px;
  background: linear-gradient(
    135deg,
    #feffea,
    #e3eeff,
    #cdecff,
    #feffe6
  );
  background-size: 100% 100%;
  /* color: #ffffff; */ /* Makes text more readable on dark background */
}


.inner {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center;
  text-align: center; */
  /* padding: 2rem 0; */
}

.content {
  max-width: 100%;
  margin-bottom: 2rem;
}

.image {
  max-width: 100%;
  height: auto;
}

.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensure the group takes up the full width */
  justify-content: center; /* Center the buttons within the group */
}

/* Responsive layout for medium screens (tablets) */
@media (min-width: 768px) {
  .inner {
    flex-direction: row;
    text-align: left;
    padding: 4rem 0;
  }

  .content {
    max-width: 50%;
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .image {
    max-width: 50%;
  }

  .group {
    flex-direction: row;
    justify-content: flex-start; /* Align buttons to the left on larger screens */
    width: auto; /* Allow the group to shrink to content width on larger screens */
  }
}

.title {
  font-size: rem(34px);
  font-weight: 900;

  @media (max-width: mantine-breakpoint-sm) {
    font-size: rem(24px);
  }
}

.description {
  max-width: rem(600px);
  margin: auto;

  &::after {
    content: "";
    display: block;
    background-color: var(--mantine-color-blue-filled);
    width: rem(45px);
    height: rem(2px);
    margin-top: var(--mantine-spacing-sm);
    margin-left: auto;
    margin-right: auto;
  }
}

.card {
  border: rem(1px) solid
    light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}

.cardTitle {
  &::after {
    content: "";
    display: block;
    background-color: var(--mantine-color-blue-filled);
    width: rem(45px);
    height: rem(2px);
    margin-top: var(--mantine-spacing-sm);
  }
}

.footer {
  margin-top: rem(120px);
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-6)
  );
  border-top: rem(1px) solid
    light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
}

.description2 {
  margin-top: 5px;

  @media (max-width: 768px) {
    /* Assuming $mantine-breakpoint-sm is 768px */
    margin-top: var(--mantine-spacing-xs);
    text-align: center;
  }
}

.inner2 {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.groups2 {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    display: none;
  }
}

.wrapper {
  width: 160px; /* Changed rem(160px) to plain px */
}

.link {
  display: block;
  font-size: var(--mantine-font-size-sm);
  padding-top: 3px; /* Changed rem(3px) to plain px */
  padding-bottom: 3px; /* Changed rem(3px) to plain px */

  &:hover {
    text-decoration: underline;
  }
}

.title2 {
  font-family: Greycliff CF, var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  color: var(--mantine-color-black); /* Use CSS variable directly */
}

.afterFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: 1px solid var(--mantine-color-gray-2); /* Use CSS variable directly */

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.social {
  @media (max-width: 768px) {
    margin-top: var(--mantine-spacing-xs);
  }
}

.wrapper3 {
  display: flex;
  align-items: center;
  padding: calc(var(--mantine-spacing-xl) * 2);
  border-radius: var(--mantine-radius-md);
  background-color: var(
    --mantine-color-white
  ); /* Assuming light-dark mixin logic */
  border: 1px solid var(--mantine-color-gray-3); /* Assuming light-dark mixin logic */

  @media (max-width: 768px) {
    /* Assuming $mantine-breakpoint-sm is 768px */
    flex-direction: column-reverse;
    padding: var(--mantine-spacing-xl);
  }
}

.image3 {
  max-width: 40%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.body3 {
  padding-right: calc(var(--mantine-spacing-xl) * 4);

  @media (max-width: 768px) {
    padding-right: 0;
    margin-top: var(--mantine-spacing-xl);
  }
}

.title3 {
  color: var(--mantine-color-black); /* Assuming light-dark mixin logic */
  font-family: Greycliff CF, var(--mantine-font-family);
  line-height: 1;
  margin-bottom: var(--mantine-spacing-md);
}

.controls3 {
  display: flex;
  margin-top: var(--mantine-spacing-xl);
}

.inputWrapper3 {
  width: 100%;
  flex: 1;
}

.input3 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.control3 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* header */

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px; /* Converted rem(56px) to plain px */
  background-color: var(--mantine-color-body);
  border-bottom: 1px solid var(--mantine-color-gray-5); /* Assuming light mode */
  z-index: 1000;

  /* Dark mode support */
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid var(--mantine-color-dark-4); /* Dark mode border color */
  }
}

.innerH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px; 
}

.linkH {
  display: block;
  line-height: 1;
  padding: rem(8px) rem(12px);
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;

  /* Hover effect */
  .linkH:hover {
    background-color: var(--mantine-color-gray-0); /* Assuming light mode */
  }

  /* Dark mode hover effect */
  @media (prefers-color-scheme: dark) {
    .your-class:hover {
      background-color: var(--mantine-color-dark-6);
    }
  }

  /* Active state styling */
  [data-mantine-color-scheme] .your-class[data-active] {
    background-color: var(--mantine-color-blue-filled);
    color: var(--mantine-color-white);
  }
}

/* Feature Wrapper */
.featureWrapper {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  margin-bottom: 8rem;
}

/* Features Container */
.featuresContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem; /* distance between the feature text and image */
}

/* Row Reverse Class */
.rowReverse {
  flex-direction: row-reverse;
}

/* Image Section */
.imageSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Text Section */
.textSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Image Wrapper */
.imageWrapper {
  width: 100%;
  max-width: 650px;
}

/* Feature Image */
.featureImage {
  width: 100%;
  height: auto;
}

/* Feature Text */
.featureText {
  max-width: 600px;
  margin: 0 auto;
}

@media (max-width: 1279px) {
  .featuresContainer {
    flex-direction: column;
  }

  .rowReverse {
    flex-direction: column;
  }

  .featureText {
    width: 90vw;
  }
}

.highlightListWrapper {
  display: flex;
  justify-content: center;
}

.highlightList {
  text-align: left;
}

@keyframes slideInFade {
  from {
    opacity: 0;
    transform: translateX(100px); /* Adjust the X value to control the slide distance */
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideInFade {
  animation: slideInFade 1s ease-out; /* Adjust duration and timing function as needed */
}

.logoImage {
  height: 3rem;
  width: auto;
  max-width: 100%;
  @media (max-width: 768px) {
    height: 2rem;
  }
}
